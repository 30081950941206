
import { Component, Vue } from 'vue-property-decorator';

import {
  ContextColorProvider,
  NavBar,
  Tabs,
  TabList,
  Tab,
  SkeletonLoader,
  HelperViewStatus,
  ButtonIconContextual,
  ButtonContainer,
  ButtonSecondary
} from '@warrenbrasil/nebraska-web';
import {
  ShowMoneyToggleButton,
  BackButton
} from '@/modules/common/components/buttons';
import { CashMarket } from './components/CashMarket';
import { StockLending } from './components/StockLending';
import { Derivatives } from './components/Derivatives';
import { CustomOperations } from './components/CustomOperations';
import { TabsListLoader, TabContentLoader } from './components/Loaders';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  getPositionsSummary,
  PositionsGroups,
  PositionSummary
} from '@/modules/trade/services/instruments';
import { ModalPaidCustodyCanceled } from './components/ModalPaidCustodyCanceled';

@Component({
  components: {
    ContextColorProvider,
    BackButton,
    NavBar,
    ShowMoneyToggleButton,
    CashMarket,
    StockLending,
    Tabs,
    TabList,
    Derivatives,
    Tab,
    SkeletonLoader,
    TabsListLoader,
    TabContentLoader,
    HelperViewStatus,
    CustomOperations,
    ButtonIconContextual,
    ButtonContainer,
    ButtonSecondary,
    ModalPaidCustodyCanceled
  }
})
export default class TradePositionsView extends Vue {
  readonly NebraskaColors = NebraskaColors;
  readonly PositionsGroups = PositionsGroups;

  tabsData: PositionSummary[] = [];
  currentTabIndex = 0;
  loading = false;
  error = false;
  tabSelected = this.$route?.query?.group as PositionsGroups;
  paidCustodyActive = false;
  hasStockLendingPositions = false;
  modalPaidCustodyCanceled = true;

  async created() {
    await this.fetchPositionsTabs();
  }

  get computedNavBarClass() {
    return ['positions__navbar', 'positions__navbar--border'];
  }

  get currentTabGroup() {
    return this.tabsData[this.currentTabIndex]?.description.value;
  }

  get hasTabs() {
    return this.tabsData.length > 0;
  }

  get showButtonCancelPaidCustody() {
    return (
      this.currentTabGroup === PositionsGroups.STOCK_LENDING &&
      this.paidCustodyActive
    );
  }

  updateHasStockLendingPositions(value: boolean) {
    this.hasStockLendingPositions = value;
  }

  handleCloseModalPaidCustodyCanceled() {
    this.modalPaidCustodyCanceled = false;
  }

  handleNextClick() {
    return this.$router.push({
      name: 'paidCustody',
      params: { status: 'cancel' }
    });
  }

  setGroupQuery(group: PositionsGroups) {
    const query = { ...this.$route.query, group };
    this.$router.replace({ query });
  }

  onChangeTab(selectedTabIndex: number) {
    this.currentTabIndex = selectedTabIndex;
    const tabGroup = this.tabsData[selectedTabIndex].description.value;

    if (this.$route.query?.group !== tabGroup) this.setGroupQuery(tabGroup);
  }

  setInitialTab() {
    const group = this.$route.query?.group as PositionsGroups;
    const tabIndex = this.tabsData.findIndex(
      tab => tab.description.value === group
    );

    this.onChangeTab(tabIndex === -1 ? 0 : tabIndex);
  }

  async fetchPositionsTabs() {
    try {
      this.loading = true;
      this.error = false;

      this.tabsData = await getPositionsSummary();

      if (this.tabsData.length > 0) this.setInitialTab();
    } catch {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  handleFrequentlyAskedQuestions() {
    return this.$router.push({ name: 'faq' });
  }
}
