
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import {
  Heading,
  BaseText,
  FilterSelect,
  IFilterOption,
  SkeletonLoader
} from '@warrenbrasil/nebraska-web';
import {
  HeaderFilterLoader,
  HeaderSubtitleLoader
} from '@/modules/trade/views/TradePositionsView/components/Loaders';

import { StringFormatted } from '@/modules/trade/types';
import { IStockLendingFilters, StockLendingFiltersTypes } from '../../types';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: {
    Heading,
    BaseText,
    FilterSelect,
    SkeletonLoader,
    HeaderFilterLoader,
    HeaderSubtitleLoader
  }
})
export default class StockLendingFilters extends Vue {
  @Prop({ type: Object, required: true })
  readonly updatedAt!: StringFormatted;

  @Prop({ type: String, required: true })
  readonly totalItems!: string;

  @Prop({ type: Object, required: true })
  readonly filters!: IStockLendingFilters;

  @Prop({ type: Boolean, default: false })
  readonly isLoading!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly error!: boolean;

  @Emit('on-filter-change')
  onFilterChange(
    _filterType: StockLendingFiltersTypes,
    _filter: IFilterOption
  ) {}

  readonly StockLendingFiltersTypes = StockLendingFiltersTypes;
  readonly NebraskaColors = NebraskaColors;

  get updatedAtFormatted() {
    // When has error returns a special character that has zero width space to preserve the height of heading display
    return this.error ? '​' : this.updatedAt?.formatted || '-';
  }

  get typeOptions() {
    return this.filters[StockLendingFiltersTypes.Type].available.map(
      this.formatFilterOption
    );
  }

  get hasTypeOptions() {
    return this.typeOptions.length > 0;
  }

  formatFilterOption(option: StringFormatted): IFilterOption {
    return {
      value: option.value,
      label: option.formatted
    };
  }

  handleEmitOnFilterChange(
    filterKey: StockLendingFiltersTypes,
    filterValue?: string | number
  ) {
    const filter = this.filters[filterKey].available.find(
      ({ value }) => value === filterValue
    ) as StringFormatted;

    this.onFilterChange(filterKey, this.formatFilterOption(filter));
  }
}
