
import { Component, Vue, Emit } from 'vue-property-decorator';
import { Notification } from '@/modules/common/components/Notification';
import { deleteAccount } from '@/modules/common/services/http/account';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import {
  HelperViewStatus,
  Modal,
  ButtonContainer,
  ButtonPrimary,
  ButtonSecondary,
  HeadingDisplay
} from '@warrenbrasil/nebraska-web';

@Component({
  components: {
    Modal,
    ButtonContainer,
    ButtonPrimary,
    ButtonSecondary,
    HeadingDisplay,
    HelperViewStatus,
    Notification
  }
})
export default class DeleteAccount extends Vue {
  private showErrorDeleteModal = false;
  private errorDeleteModalSubtitle = '';

  @Emit()
  private close() {}

  private async deleteConfirm() {
    deleteAccount().then(
      () => {
        this.$notify({
          group: 'wrn-notification-delete-account',
          text: 'Conta deletada com sucesso!',
          type: NotificationTypes.success
        });
        setTimeout(() => {
          this.$router.push({ name: 'signin' });
        }, 1000);
      },
      err => {
        if (err.message === 'customer_balance_not_zero') {
          this.errorDeleteModalSubtitle =
            'Sua conta só pode ser encerrada após o resgate de todo o dinheiro que você tem investido na Warren.';
        } else {
          this.errorDeleteModalSubtitle =
            'Sua conta tem pendências e não pode ser encerrada neste momento. Se precisar de ajuda, entre em contato com nosso atendimento.';
        }
        this.shouldShowErrorDeleteModal();
      }
    );
  }

  private shouldShowErrorDeleteModal() {
    this.showErrorDeleteModal = true;
  }
}
